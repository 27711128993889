<template>
  <div>
    <v-card>
      <v-card-text class="pl-1 pr-1">
        <v-col col="12">
          <v-card>
            <v-app-bar color="primary" dense>
              <v-toolbar-title class="white--text">Cliente</v-toolbar-title>
            </v-app-bar>
            <v-card-text class="bordaappbar">
              <v-row dense>
                <v-col cols="1" offset="2">
                  <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly class="fundo-readonly"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field outlined dense hide-details label="IdClicktime" v-model="Model.IdClicktime" readonly class="fundo-readonly"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field outlined dense hide-details label="IdOmie" v-model="Model.IdOmie" readonly class="fundo-readonly"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn block @click="RegistrarOmie(Model)" v-if="Model.IdOmie == 0" color="warning">Registrar no Omie</v-btn>
                </v-col>
                <v-col col="4" md="3" lg="1">
                  <v-switch hide-details inset label="Ativo" color="warning" v-model="Model.FlagAtivo" :false-value="0" :true-value="1">{{ Model.FlagInativo }}</v-switch>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="2" offset="2">
                  <v-text-field outlined dense hide-details label="Sigla" v-model.trim="Model.Sigla"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field outlined dense hide-details label="Nome" v-model.trim="Model.Nome"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select outlined dense label="Moeda Faturamento" v-model="Model.MoedaFaturamento" :items="Moedas" item-text="MoedaNome" item-value="MoedaCodigo"></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6" offset="2">
                  <v-text-field outlined dense hide-details label="E-Mail" v-model="Model.Email"></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-text-field outlined dense hide-details label="Prazo de Pagamento" v-model="Model.PrazoPagamento" @keypress="Model.DiaVencimento = 0"></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-text-field outlined dense hide-details label="Dia de Vencimento" v-model="Model.DiaVencimento" @keypress="Model.PrazoPagamento = 0"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-textarea outlined dense hide-details rows="3" label="Endereço Faturamento" v-model="Model.EnderecoFaturamento"></v-textarea>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-textarea outlined dense hide-details rows="3" label="Observação" v-model="Model.Observacao"></v-textarea>
                </v-col>
              </v-row>

              <v-row dense class="pt-16">
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-row>
                    <v-col>
                      <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.Id != 0">Excluir Pessoa</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn block @click="Cancelar()" color="warning">Cancelar</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn block @click="ShowConfirmaSalvar = true" color="primary">{{ Model.Id == 0 ? "Incluir" : "Salvar" }}</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-card-text>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PessoaEdit",
  props: {
    idPessoa: {
      type: Number,
      default: 0,
    },
    fromRelatorio: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      Retorno: [],
      Moedas: [ {MoedaCodigo: 1, MoedaNome: "R$ - Real"},
                {MoedaCodigo: 2, MoedaNome: "U$ - Dólar"},
      ],
    };
  },
  methods: {
    Cancelar() {
      // if (!this.fromRelatorio) {
      this.$router.push("/Cadastro/Clientes/Index").catch(() => {});
      this.Index();
      // } else {
      // this.$emit("retorno");
      // }
    },
    Edit(Id) {
      axios.get("/api/Cadastro/Pessoas/Edit/".concat(Id)).then((response) => {
        //Pega os dados do response e joga no objeto Model
        this.Model = response.data.Data;
      });
    },
    RegistrarOmie(model) {
      axios.get("/api/Cadastro/Pessoas/IntegraCliente/".concat(model.Id).concat("/").concat(model.Nome)).then((response) => {
        //Pega os dados do response e joga no objeto Model
        this.Retorno = response.data.Data;
        this.Model.IdOmie = this.Retorno.codigo_cliente_omie;
        this.$toast.success("Cliente registrado no Omie sob nº: ".concat(this.Model.IdOmie), "", { timeout: 5000 });
      });
    },
    Save() {
      this.Model.FlagPessoa = "P";
      this.Model.FlagCliente = 1;
      axios({ method: "post", data: this.Model, url: "/api/Cadastro/Pessoas/Save" })
        .then((response) => {
          if (!this.fromRelatorio) {
            this.$toast.success("Registro salvo com sucesso", { timeout: 2000 });
            this.Index();
          } else {
            this.$emit("retorno");
          }
        })
        .catch((error) => {
          let a = 1;
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Cadastro/Pessoas/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Cadastro/Clientes/Index");
    },
  },
  mounted: function () {
    //Pega a Id enviada pela rota
    let Id = this.$route.params.id;

    if (Id != null) {
      //se for null, volta para o Index
      this.Edit(Id);
    } else {
      //this.$router.push("/Cadastro/Pessoas/Index");
      this.Edit(this.idPessoa);
    }
  },
};
</script>
