<template>
  <v-container>
    <!-- <div class="vld-parent"> -->
    <!-- <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading> -->
    <v-row class="mt-15">
      <v-spacer></v-spacer>
      <v-col cols="12"><span class="text-center"></span></v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="mt-15">
      <v-spacer></v-spacer>
      <v-btn color="success" role="link" @click="RunImportacao()">Efetuar Importação do ClickTime</v-btn>
      <v-spacer></v-spacer>
    </v-row>
    <!-- </div> -->
  </v-container>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Importacao",
  data() {
    return {
      Model: {},
      // isLoading: false,
      // fullPage: true,
    };
  },
  components: {
    Loading,
  },
  methods: {
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    async RunImportacao() {
      this.$toast.info("Importando Cadastros...\nAguarde...", { timeout: false });
      await axios.get("/api/ClickTime/ImportAll/".concat("1")).then((response) => {
        this.$toast.clear();
        this.$toast.info("Importando Despesas...\nAguarde...", { timeout: false });
        axios.get("/api/ClickTime/ImportAll/".concat("2")).then((response) => {
          this.$toast.clear();
          this.$toast.info("Importando Lançamentos...\nAguarde...", { timeout: false });
          axios.get("/api/ClickTime/ImportAll/".concat("3")).then((response) => {
            this.$toast.clear();
            this.$toast.success("Dados Importados...", { timeout: 5000 });
          });
        });
      });
    },
  },
  created: {},
  mounted() {},
};
</script>
