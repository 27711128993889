<template>
  <div>
    <v-card>
      <v-card-text class="pl-1 pr-1">
        <v-col col="12">
          <v-card>
            <v-app-bar color="primary" dense>
              <v-toolbar-title class="white--text">Caso</v-toolbar-title>
            </v-app-bar>
            <v-card-text class="bordaappbar">
              <v-row dense>
                <v-col col="4" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
                  <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
                </v-col>

                <v-col col="4" md="3" lg="1">
                  <v-switch hide-details inset label="Cobrança" color="warning" v-model="Model.FlagCobranca" :false-value="0" :true-value="1"></v-switch>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-autocomplete return-object :items="Casos" item-text="Nome" item-value="Id" v-model="Model.Caso" label="Caso" dense outlined hide-details></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-autocomplete return-object :items="Clientes" item-text="Nome" item-value="Id" v-model="Model.Cliente" label="Cliente" dense outlined hide-details></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-autocomplete return-object :items="Advogados" item-text="Nome" item-value="Id" v-model="Model.Advogado" label="Advogado" dense outlined hide-details></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-autocomplete return-object :items="Tarefas" item-text="Nome" item-value="Id" v-model="Model.Tarefa" label="Tarefa" dense outlined hide-details></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="4" lg="4" offset="0" offset-md="2" offset-lg="2">
                  <dtPicker label="Data Lançamento" v-model="Model.DataLancamento"></dtPicker>
                </v-col>
                <v-col col="12" md="2" lg="2">
                  <v-text-field outlined dense hide-details label="Horas" v-model="Model.Horas"></v-text-field>
                </v-col>
                <v-col col="12" md="2" lg="2">
                  <v-text-field outlined dense hide-details label="Horas Cobradas" v-model="Model.HorasCobranca"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="4" lg="4" offset="0" offset-md="2" offset-lg="2">
                  <v-currency-field prefix="R$" outlined dense hide-details label="Valor Hora Padrão" v-model="Model.ValorHoraPadrao"></v-currency-field>
                </v-col>
                <v-col col="12" md="2" lg="2">
                  <v-currency-field prefix="R$" outlined dense hide-details label="Valor Lançamento Padrão" v-model="Model.ValorLancamentoPadrao"></v-currency-field>
                </v-col>
                <v-col col="12" md="2" lg="2">
                  <v-currency-field prefix="R$" outlined dense hide-details label="Valor Lançamento Faturado" v-model="Model.ValorLancamentoFaturado"></v-currency-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-textarea outlined dense hide-details rows="3" label="Descrição" v-model="Model.Descricao"></v-textarea>
                </v-col>
              </v-row>

              <v-row dense class="pt-16">
                <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                  <v-row>
                    <v-col>
                      <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.Id != 0">Excluir Lancamento</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn block @click="Cancelar()" color="warning">Cancelar</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn block @click="ShowConfirmaSalvar = true" color="primary">{{ Model.Id == 0 ? "Incluir" : "Salvar" }}</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-card-text>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PreliminarEdit",
  props: {
    idLancamento: {
      type: Number,
      default: 0,
    },
    fromRelatorio: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      Advogados: [],
      Clientes: [],
      Casos: [],
      Tarefas: [],
    };
  },
  methods: {
    Cancelar() {
      // if (!this.fromRelatorio) {
      this.$router.push("/Preliminar/Index").catch(() => {});
      this.Index();
      // } else {
      // this.$emit("retorno");
      // }
    },
    Edit(Id) {
      axios.get("/api/Preliminares/Edit/".concat(Id)).then((response) => {
        //Pega os dados do response e joga no objeto Model
        this.Model = response.data.Data;
      });
    },
    Save() {
      this.Model.FlagLancamento = "P";
      axios({ method: "post", data: this.Model, url: "/api/Preliminares/Save" })
        .then((response) => {
          if (!this.fromRelatorio) {
            this.$toast.success("Registro salvo com sucesso", { timeout: 2000 });
            this.Index();
          } else {
            this.$emit("retorno");
          }
        })
        .catch((error) => {
          let a = 1;
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Preliminares/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Preliminar/Index");
    },
    GetListas() {
      const instance = axios.create();
      const GetAdvogados = instance.get("/api/Listas/Advogados");
      const GetClientes = instance.get("/api/Listas/Clientes");
      const GetCasos = instance.get("/api/Listas/Casos");
      const GetTarefas = instance.get("/api/Listas/Tarefas");
      axios
        .all([GetAdvogados, GetClientes, GetCasos, GetTarefas])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.Advogados.push(item);
            });

            responses[1].data.forEach((item) => {
              this.Clientes.push(item);
            });

            responses[2].data.forEach((item) => {
              this.Casos.push(item);
            });

            responses[3].data.forEach((item) => {
              this.Tarefas.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
  },
  mounted: function () {
    //Pega a Id enviada pela rota
    let Id = this.$route.params.id;
    this.GetListas();

    if (Id != null) {
      //se for null, volta para o Index
      this.Edit(Id);
    } else {
      //this.$router.push("/Cadastro/Preliminars/Index");
      this.Edit(this.idLancamento);
    }
  },
};
</script>
