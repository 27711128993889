<template>
  <v-app>
    <v-overlay v-model="overlay" persistent :opacity="0.98" />
    <v-navigation-drawer v-if="usrLogged && !ModoRelatorio" v-model="drawer" app class="d-print-none" mini-variant-width="80px">
      <v-list-item two-lines>
        <!-- class="px-2"  -->
        <v-list-item-avatar color="black">
          <span class="white--text">FAT</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-center"><b>SMA BRASIL</b></v-list-item-title>
          <v-list-item-title class="text-center">
            <b>FATURAMENTO</b>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="px-2">
        <!-- <v-list-item-avatar>
          <img alt="Avatar" src="./assets/userIcon.png" />
        </v-list-item-avatar> -->
        <v-list-item-title class="text-capitalize text-center">{{ usrOpe.Nome }}</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <Menu></Menu>
    </v-navigation-drawer>
    <v-app-bar v-if="usrLogged && !ModoRelatorio" app fixed dense dark color="primary" class="d-print-none">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        SISTEMA DE FATURAMENTO
        <v-icon>mdi-chevron-right</v-icon>
        {{ TituloTela }}
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-divider class="mx-4" vertical></v-divider>
      <v-divider class="mx-4" vertical></v-divider>
      <!-- <v-idle @idle="onidle" :duration="TimeoutBaseTime" /> -->
      <TimeoutControl @idle="onidle" @remind="onremind" :duration="TimeoutBaseTime" :reminders="[60]" />
      <!-- Contador regressivo logout 1200-->
      <v-divider class="mx-4" vertical></v-divider>
      <TrocaSenha></TrocaSenha>
      <v-divider class="mx-4" vertical></v-divider>
      <v-btn fab small color="secondary" @click="ShowConfirmaSair = true">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <div id="dialog-holder">
        <v-container fluid>
          <!-- {{ $route.fullPath }} -->
          <!-- <keep-alive include="FaturamentoIndex,FaturamentoEdit"> -->
          <router-view />
          <!-- </keep-alive> -->
        </v-container>
      </div>
    </v-main>

    <confirm Msg="Sair do Sistema?" :Show="ShowConfirmaSair" v-on:confirm="Logout(), (ShowConfirmaSair = false)" v-on:cancel="ShowConfirmaSair = false"></confirm>

    <loading v-if="showLoading"></loading>
    <v-snackbar :color="snackbar.color" :timeout="snackbar.timeout" :stackable="true" app top dense multi-line right v-model="snackbar.show" class="d-print-none">
      <!-- snackbar.color -->
      <section v-html="snackbar.msg"></section>
      <template v-slot:[`action`]="{ attrs }">
        <v-btn text v-on:click="snackbar.show = false" v-bind="attrs">
          <v-icon dark>mdi-close-box-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style>
.v-dialog--fullscreen {
  padding-top: 70px;
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  /* left: auto !important; */
}

.v-input--switch {
  margin-top: 4px !important;
}

.disable-style {
  color: rgba(0, 0, 0, 0.4) !important;
}

.disable-style .v-text-field__slot input {
  color: rgba(0, 0, 0, 0.4) !important;
}

.rowdisabled {
  background-color: #ffcdd2 !important;
  border-color: #ffcdd2 !important;
}
.v-data-table tbody tr:nth-of-type(even) {
  background-color: #eeeeee !important;
}
.v-data-table tbody tr:hover {
  background: #bdbdbd !important;
}

.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: white !important;
  border-color: black;
  border-bottom: 1px;
  border-left: 1px;
  border-right: 1px;
}

.v-data-table__expanded.v-data-table__expanded__content > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.v-data-table__expanded.v-data-table__expanded__content > .inner-data-table {
  box-shadow: none !important;
  background-color: white !important;
}

.v-data-table tbody tr {
  cursor: pointer !important;
}

/* https://coolors.co/palette/595959-7f7f7f-a5a5a5-cccccc-f2f2f2  */
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 14px !important;
  color: black !important;
  background-color: #cccccc !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 14px !important;
}

.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.v-data-table tbody tr.v-data-table__selected {
  background: rgba(25, 118, 210, 0.5) !important;
}
.v-data-table tbody tr.v-data-table__selected:hover {
  background: rgba(25, 118, 210, 0.55) !important;
}
.v-list-item.primary--text.v-list-item--active.v-list-item--link.theme--light.v-list-item--highlighted {
  background-color: #1976d2 !important;
  color: white !important;
}
.fontwhite {
  color: white;
}

.bordagrid {
  border: 2px solid black;
}

.bordaappbar {
  border: 2px solid #1976d2;
}

.fundo-readonly {
  background-color: #d1d1d1;
}

.gridrowred {
  background-color: error;
}

.gridrowgreen {
  background-color: success;
}

.titulobusca {
  font-weight: bold;
}

.borda {
  border: 1px solid darkgray;
}
</style>

<script>
import axios from "axios";
import loading from "./components/Loading.vue";
import CountDownLogoff from "./components/CountDownLogoff.vue";
import TimeoutControl from "./components/TimeoutControl.vue";
import Menu from "./components/Menu.vue";
import moment from "moment";
import TrocaSenha from "./components/TrocaSenha.vue";

export default {
  name: "App",
  data: () => ({
    drawer: true,
    snackbar: {
      show: false,
      color: "red",
      msg: "",
      timeout: 6000 * 2,
    },
    IDLE_TIMEOUT: 5, //1200,
    idleSecondsTimer: 0,
    idleSecondsCounter: 0,
    TimeToLogout: "",
    isBlinking: false,
    LastTime: null,
    TimeCompare: 0,
    FirstTime: 0,
    IsLogged: null,
    LastIsLogged: null,
    overlay: false,
    TimeoutBaseTime: 10 * 60, // 10 minutos sem atividade
    Rotas: [],
    ShowConfirmaSair: false,
    // FUNÇÃO PARA VER RESOLUÇÃO DA TELA
    screenSize: "", // To store the screen size
    screenWidth: "",
    screenHeight: "",
    // FIM
  }),
  components: {
    loading: loading,
    CountDownLogoff: CountDownLogoff,
    TimeoutControl: TimeoutControl,
    Menu: Menu,
    TrocaSenha: TrocaSenha,
  },
  methods: {
    onResize() {
      // FUNÇÃO PARA VER RESOLUÇÃO DA TELA
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;

      if (this.screenWidth < 600) {
        this.screenSize = "Extra small (xs)";
      } else if (this.screenWidth < 960) {
        this.screenSize = "Small (sm)";
      } else if (this.screenWidth < 1264) {
        this.screenSize = "Medium (md)";
      } else if (this.screenWidth < 1904) {
        this.screenSize = "Large (lg)";
      } else {
        this.screenSize = "Extra large (xl)";
      }
      // FIM
    },
    onidle() {
      // this.overlay = true;
      console.log("=========================================> LOGOUT POR INATIVIDADE VIA JAVASCRIPT");
      this.Logout();
    },
    onremind(time) {
      // alert seconds remaining to 00:00
      alert(time);
    },
    msgBox(msg, num) {
      this.snackbar.show = true;
      this.snackbar.msg = msg;
    },
    GetIP() {
      //return axios("https://checkip.amazonaws.com/");
    },
    Logout() {
      axios({
        method: "post",
        url: "/api/Sistema/Usuarios/Logout",
      }).then((response) => {
        this.$store.commit("SET_USROPE", {});
        this.$store.commit("SET_USERLOGGED", false);
        this.$store.commit("SET_MODORELATORIO", false);
        this.$router.push("/Login");
      });
    },
    startBlinking() {
      this.isBlinking = true;
    },
    stopBlinking() {
      this.isBlinking = false;
    },
    KeepAlive() {
      //! Mantenho a sessao logada via acesso à rota /IsLogged, que só retorna true ou false
      //console.log("Track", this.usrLogged);
      if (this.usrLogged) {
        var TimeStampNow = new Date().getTime();
        //console.log("Keep Alive", TimeStampNow - this.LastIsLogged);
        if ((TimeStampNow - this.LastIsLogged) / 1000 > 15) {
          var instancia = axios.create();
          instancia.get("/api/Sistema/Usuarios/IsLogged").then(
            (response) => {
              this.IsLogged = response.data;
            },
            (error) => {
              //console.log(error);
            }
          );
          this.LastIsLogged = TimeStampNow;
          //console.log("Keep Alive", this.IsLogged);
        }
      }
    },

  },
  beforeUnmount() {
    // FUNÇÃO PARA VER RESOLUÇÃO DA TELA
    window.removeEventListener("resize", this.onResize);
    // FIM
  },
  mounted: function () {
    console.clear;
    // FUNÇÃO PARA VER RESOLUÇÃO DA TELA
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.DolarHoje = 2;
    // this.GetDolarHoje();
    // FIM
    document.onclick = () => {
      this.stopBlinking();
      this.$store.commit("SET_COUNTDOWNLOGOFFRESET", !this.$store.getters.CountDownLogoffReset);
      this.KeepAlive();
    };
    document.onmousemove = () => {
      this.stopBlinking();
      this.$store.commit("SET_COUNTDOWNLOGOFFRESET", !this.$store.getters.CountDownLogoffReset);
      this.KeepAlive();
    };
    document.onkeypress = () => {
      this.stopBlinking();
      this.$store.commit("SET_COUNTDOWNLOGOFFRESET", !this.$store.getters.CountDownLogoffReset);
      this.KeepAlive();
    };

  },
  //
  computed: {
    mini() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    usrLogged() {
      return this.$store.getters.usrLogged;
    },
    ModoRelatorio() {
      return this.$store.getters.ModoRelatorio;
    },
    showLoading() {
      return this.$store.getters.showLoading;
    },
    usrOpe() {
      return this.$store.getters.usrOpe;
    },
    TituloTela() {
      return this.$store.getters.TituloTela;
    },
    Rota() {
      let itens = this.$route.path.split("/");
      itens[0] = "#";
      let rotas = [];
      itens.forEach((item) => {
        rotas.push({ text: this.$route.params.lenth > 0 ? this.$route.params[0] : item });
      });

      return rotas;
    },
  },
  activated() {},
  beforeCreate() {},
  beforeUpdate() {},
  created() {
        axios.interceptors.request.use(
      (config) => {
        this.$store.commit("SET_COUNTDOWNLOGOFFRESET", !this.$store.getters.CountDownLogoffReset);
        this.$store.commit("SET_LOADING", true);
        return config;
      },
      (error) => {
        this.$store.commit("SET_LOADING", false);
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        this.$store.commit("SET_LOADING", false);
        return response;
      },
      (error) => {
        this.$store.commit("SET_LOADING", false);

        switch (error.response.status) {
          case 401:
            window.location.href = "/Logout";
            break;

          case 403:
            window.location.href = "/Denied";
            break;

          case 500:
            this.$router.push({ name: "Error", params: { mensagem: error.response.data } });
            break;

          default:
            {
              if (error.response.status == 400 && error.response.data.Msg.indexOf("validation") != -1) {
                //this.msgBox(error.response.data.errors, error.response.data.status + " - " + error.response.data.title);
                this.$toast(error.response.data.errors + "\n" + error.response.data.status + " - " + error.response.data.title, { type: "error" });
              } else if (error.response.data.Success == false) {
                //this.msgBox(error.response.data.Msg, error.response.status);
                this.$toast(error.response.data.Msg + "\n" + error.response.data.status, { type: "error" });
              }
            }
            break;
        }

        return Promise.reject(error);
      }
    );

  },
};
</script>
