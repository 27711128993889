<template>
  <div class="bordaappbar">
    <v-data-table class="elevation-1 row-pointer" :headers="Header" mobile-breakpoint="1" :items="GridData" :item-class="RowClass" :sort-by.sync="Pagination.sortBy" :must-sort="true" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
      <template v-slot:top>
        <v-toolbar dense flat>
          <v-col col="6" offset="2">
            <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" v-on:keyup.enter="Index()"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-switch v-model="Pagination.Filtro.EmAberto" dense false-value="false" true-value="true" label="Filtrar em aberto" hide-details inset  @click="Index()"></v-switch>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn to="/Faturamento/Edit/0" color="primary">
            Nova
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <br />
      </template>

      <template v-slot:[`item.Data`]="{ item }">
        <span class="text-no-wrap">
          {{ item.Data | dateBR }}
        </span>
      </template>

      <template v-slot:[`item.DataVencimento`]="{ item }">
        <span class="text-no-wrap">
          {{ item.DataVencimento | dateBR }}
        </span>
      </template>

      <template v-slot:[`item.DataPagamento`]="{ item }">
        <span class="text-no-wrap">
          {{ item.DataPagamento | dateBR }}
        </span>
      </template>

      <template v-slot:[`item.IdOmie`]="{ item }">
        <span class="text-no-wrap">
          <v-img src="../../assets/omie.png" width="50px" v-if="item.IdOmie > 0"></v-img>
        </span>
      </template>

      <template v-slot:[`item.ValorFaturado`]="{ item }">
        <span class="text-no-wrap">
          {{ FormataMoeda(item.ValorFaturado, item.MoedaFaturamento) }}
        </span>
      </template>

      <template v-slot:[`item.MoedaFaturamento`]="{ item }">
        <span class="text-no-wrap">
          <span v-if="item.MoedaFaturamento == 1">R$</span>
          <span v-if="item.MoedaFaturamento == 2">U$</span>
        </span>
      </template>

      <template v-slot:[`item.ValorPago`]="{ item }">
        <span class="text-no-wrap">
          {{ FormataMoeda(item.ValorPago, item.MoedaFaturamento) }}
        </span>
      </template>

      <template v-slot:[`item.Action3`]="{ item }">
        <v-btn @click="DownloadFaturamento(item)" color="primary" enabled="IdPreliminar>0">Nota de Débito</v-btn>
      </template>

      <template v-slot:[`item.Action2`]="{ item }">
        <v-btn @click="(ShowExcluiFaturamento = true), (itemFaturamento = item)" color="error">Excluir</v-btn>
      </template>

      <!-- <template v-slot:[`item.Action`]="{ item }">
        <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
      </template> -->

      <template v-slot:[`item.Action4`]="{ item }">
        <v-btn @click="BaixaClick(item)" color="primary">Efetuar Baixar</v-btn>
      </template>
    </v-data-table>

    <!-- DIALOG DE BAIXA DE PAGAMENTO -->
    <v-dialog v-model="DialogBaixa" width="800px">
      <v-card>
        <v-app-bar color="primary">
          <v-toolbar-title class="white--text">Baixa de Fatura</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <v-container class="pt-10">
            <template>
              <v-row>
                <v-col cols="12" lg="6" xl="6">
                  <dtPicker label="Data Pagamento" v-model="editedItem.DataPagamento"></dtPicker>
                </v-col>
                <v-col cols="12" lg="6" xl="6">
                  <v-currency-field :prefix="editedItem.MoedaFaturamento == 1 ? 'R$' : 'U$'" outlined dense hide-details label="Valor Pago" v-model="editedItem.ValorPago"></v-currency-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="6" xl="6">
                  <!-- <v-autocomplete return-object :items="ListaPessoas" item-text="Nome" item-value="Id" v-model="editedItem.Financiador" label="Financiador" dense outlined hide-details></v-autocomplete> -->
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-10 pb-10">
          <v-spacer></v-spacer>
          <v-btn x-large color="error" @click="DialogBaixa = false">Cancelar</v-btn>
          <v-btn x-large color="primary" @click="BaixaSave()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm Msg="Confirma a exclusão Faturamento ?" :Show="ShowExcluiFaturamento" v-on:confirm="ExcluirFaturamento(itemFaturamento), (ShowExcluiFaturamento = false)" v-on:cancel="ShowExcluiFaturamento = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";
import moment, { relativeTimeThreshold } from "moment";

export default {
  name: "Faturamento",
  data() {
    return {
      GridData: [],
      DialogBaixa: false,
      editedItem: [],
      itemFaturamento: [],
      ShowExcluiFaturamento: false,
      Header: [
        { text: "Id", value: "Id", width: "100", sortable: false },
        { text: "Data", value: "Data", sortable: false },
        { text: "Cliente", value: "Cliente.Nome", width: "20%", sortable: false },
        { text: "Caso", value: "Caso.Nome", width: "20%", sortable: false },
        { text: "Valor Faturado", value: "ValorFaturado", sortable: false, align: "right" },
        { text: "Omie", value: "IdOmie", sortable: false, align: "center" },
        { text: "Vencimento", value: "DataVencimento", sortable: false },
        { text: "Pagamento", value: "DataPagamento", sortable: false },
        { text: "Valor Pago", value: "ValorPago", sortable: false, align: "right" },
        { text: "", value: "Action3", sortable: false },
        { text: "", value: "Action2", sortable: false },
        // { text: "", value: "Action", sortable: false },
        { text: "", value: "Action4", sortable: false },
      ],
      RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      Pagination: {
        page: 1,
        itemsPerPage: 50,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["DataVencimento"],
        sortDesc: [true],
        Filtro: {
          Busca: "",
          EmAberto: "true",
          FlagFaturamento: "P",
          FlagFCDG: 0,
        },
      },
    };
  },
  components: {},
  methods: {
    BaixaClick: function (item) {
      // this.editedItem = item;
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.ValorPago == 0) {
        this.editedItem.DataPagamento = moment().format("YYYY-MM-DD");
      }
      this.editedItem.ValorPago = this.editedItem.ValorFaturado;
      this.DialogBaixa = true;
    },

    BaixaSave(item) {
      axios({ method: "post", data: this.editedItem, url: "/api/Faturamento/Save" })
        .then((response) => {
          this.$toast.success("Fatura baixada.", { timeout: 2000 });
          this.DialogBaixa = false;
          this.Index();
        })
        .catch((error) => {});
    },
    Edit(Id) {
      this.$router.push("/Faturamento/Edit/".concat(Id));
    },
    Index() {
      debugger;
      //Pega os dados da controller, action Index
      axios.post("/api/Faturamento/Index", this.Pagination).then((response) => {
        //Pega os dado do Grid
        this.GridData = response.data.Data;

        //Pega os dados da Paginação
        this.Pagination = response.data.Pagination;
      });
    },
    ExcluirFaturamento(item) {
      axios.get("/api/Faturamento/ExcluirFaturamento/".concat(item.Id)).then((response) => {
        this.$toast.clear();
        if (response.data.erro_status == 0) {
        }
        this.$toast.success("Faturamento excluído e retornado ao preliminar.", { timeout: 2000 });
        this.Index();
      });
    },
    RowClass(item) {
      if (item.Ativo == false) {
        return "rowdisabled";
      }
    },
    FormataMoeda(valor, moeda) {
      var sPreMoeda = "";
      if (moeda == 1) {
        sPreMoeda = "R$ ";
      } else if (moeda == 2) {
        sPreMoeda = "U$ ";
      }
      return sPreMoeda.concat(
        parseFloat(valor)
          .toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      );
    },
    DownloadFaturamento(item) {
      axios({ method: "post", responseType: "blob", url: "/api/Listas/DownloadFaturamento/".concat(item.Id) }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", item.Cliente.Nome + "_" + item.Caso.Nome + ".pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    // RowSelect para edição
    RowSelectClick: function (item, row) {
      this.$router.push("/Faturamento/Edit/".concat(item.Id));
    },
  },
  mounted: function () {
    this.Index();
  },
};
</script>
