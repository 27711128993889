<template>
  <v-container>
    <v-row>
      <v-col>Cotação Dólar Venda: R$ {{ this.DolarHoje}}</v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-card>
          <v-card-text>
            <div>Faturas vencidas</div>
            <p class="text-h4 text--primary">12</p>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn text color="deep-purple accent-4">Learn More</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card>
          <v-card-text>
            <div>Faturas a vencer</div>
            <p class="text-h4 text--primary">23</p>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn text color="deep-purple accent-4">Learn More</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card>
          <v-card-text>
            <div>Casos não faturados</div>
            <p class="text-h4 text--primary">18</p>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn text color="deep-purple accent-4">Learn More</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-app-bar color="primary" dense>
            <v-toolbar-title class="white--text">Não Faturados ({{ this.sFirstDayNF }} a {{ this.sLastDayNF }})</v-toolbar-title>
          </v-app-bar>
          <v-card-text class="bordaappbar ma-0 pa-0">
            <v-data-table disable-pagination class="elevation-1 row-pointer" :headers="HeaderLancamentos" :items="ModelMesPassadoNaoFaturado" :hide-default-header="false" :hide-default-footer="true">
              <template v-slot:[`item.TotalLancamentos`]="{ item }">
                <span class="text-no-wrap">
                  {{ FormataMoeda(item.TotalLancamentos, item.MoedaFaturamento) }}
                </span>
              </template>
              <template v-slot:[`item.TotalDespesas`]="{ item }">
                <span class="text-no-wrap">
                  {{ FormataMoeda(item.TotalDespesas, item.MoedaFaturamento) }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-app-bar color="primary" dense>
            <v-toolbar-title class="white--text">Em Faturamento</v-toolbar-title>
          </v-app-bar>
          <v-card-text class="bordaappbar ma-0 pa-0">
            <v-data-table disable-pagination class="elevation-1 row-pointer" :headers="HeaderLancamentos" :items="ModelEmPreliminar" :hide-default-header="false" :hide-default-footer="true">
              <template v-slot:[`item.TotalLancamentos`]="{ item }">
                <span class="text-no-wrap">
                  {{ FormataMoeda(item.TotalLancamentos, item.MoedaFaturamento) }}
                </span>
              </template>
              <template v-slot:[`item.TotalDespesas`]="{ item }">
                <span class="text-no-wrap">
                  {{ FormataMoeda(item.TotalDespesas, item.MoedaFaturamento) }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-app-bar color="primary" dense>
            <v-toolbar-title class="white--text">Faturados ({{ this.sFirstDayLF }} a {{ this.sLastDayLF }})</v-toolbar-title>
          </v-app-bar>
          <v-card-text class="bordaappbar ma-0 pa-0">
            <v-data-table disable-pagination class="elevation-1 row-pointer" :headers="HeaderLancamentos" :items="ModelEmFaturamento" :hide-default-header="false" :hide-default-footer="true">
              <template v-slot:[`item.TotalLancamentos`]="{ item }">
                <span class="text-no-wrap">
                  {{ FormataMoeda(item.TotalLancamentos, item.MoedaFaturamento) }}
                </span>
              </template>
              <template v-slot:[`item.TotalDespesas`]="{ item }">
                <span class="text-no-wrap">
                  {{ FormataMoeda(item.TotalDespesas, item.MoedaFaturamento) }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment, { relativeTimeThreshold } from "moment";

export default {
  name: "Home",
  data() {
    return {
      ModelMesPassadoNaoFaturado: [],
      ModelEmPreliminar: [],
      ModelEmFaturamento: [],
      sLastDayNF: null,
      sFirstDayNF: null,
      sLastDayEF: null,
      sFirstDayEF: null,
      sLastDayLF: null,
      sFirstDayLF: null,
      DolarHoje: 1,
      HeaderLancamentos: [
        { text: "Advogado", value: "Advogado", width: "50%", sortable: false },
        { text: "Horas", value: "Horas", width: "10%", sortable: false, align: "right" },
        { text: "Lançamentos", value: "TotalLancamentos", width: "20%", sortable: false, align: "right" },
        { text: "Despesas", value: "TotalDespesas", width: "20%", sortable: false, align: "right" },
        // { text: "", value: "Action", sortable: false },
      ],
    };
  },
  components: {},
  methods: {
    Lancamentos() {
      let now = moment();
      this.Today = now.format("YYYY-MM-DD");

      // Lancamentos mês passado sem faturamento
      this.sLastDayNF = moment().subtract(1, "months").endOf("month").format("DD-MM-YYYY");
      this.sFirstDayNF = moment().subtract(1, "months").startOf("month").format("DD-MM-YYYY");
      axios.get("/api/Faturamento/Dashboard/".concat(this.sFirstDayNF).concat("/").concat(this.sLastDayNF).concat("/").concat("1")).then((response) => {
        this.ModelMesPassadoNaoFaturado = response.data;
        // Lancamentos em faturamento
        this.sFirstDayEF = "01-01-1900";
        this.sLastDayEF = "31-01-2099";
        axios.get("/api/Faturamento/Dashboard/".concat(this.sFirstDayEF).concat("/").concat(this.sLastDayEF).concat("/").concat("2")).then((response) => {
          this.ModelEmPreliminar = response.data;
          // Lancamentos faturados
          this.sLastDayLF = moment().endOf("month").format("DD-MM-YYYY");
          this.sFirstDayLF = moment().startOf("month").format("DD-MM-YYYY");
          axios.get("/api/Faturamento/Dashboard/".concat(this.sFirstDayLF).concat("/").concat(this.sLastDayLF).concat("/").concat("3")).then((response) => {
            this.ModelEmFaturamento = response.data;
          });
        });
      });
    },
    async GetDolarHoje() {
      await axios.get("/api/Listas/GetDolarHoje").then((response) => {
        // this.DolarHoje = response.data;
        // debugger;
        console.log(response.data);
        this.DolarHoje = response.data;
        return response.data;
      });
    },
    FormataMoeda(valor, moeda) {
      var sPreMoeda = "";
      if (moeda == 1) {
        sPreMoeda = "R$ ";
      } else if (moeda == 2) {
        sPreMoeda = "U$ ";
      }
      return sPreMoeda.concat(
        parseFloat(valor)
          .toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      );
    },
  },
  created: {},
  mounted: function () {
    // this.Index();
    this.GetDolarHoje();
    this.Lancamentos();
  },
};
</script>
